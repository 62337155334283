<template>
    <div>

    </div>
</template>

<script>
import { orcidCallback } from "@/api/widget"

export default {
    data() {
        return {

        }
    },
    methods: {
        // 读取地址栏地址 取出 code 参数
        getUrlParam(name) {
            const reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
            const r = window.location.search.substr(1).match(reg);
            if (r != null) return unescape(r[2]); return null;
        },
        async orcidCallbackFun() {
            const orcidCode = this.getUrlParam('code');
            const orcidState = this.getUrlParam('state') ? this.getUrlParam('state') : localStorage.getItem('orcid_state');


            console.log(orcidCode);
            console.log(orcidState);

            await orcidCallback({ code: orcidCode, state: orcidState }).then(res => {
                console.log(res);

                setTimeout(() => {
                    if (window.opener) {
                        window.opener.receiveMessageFromChild(res.data);
                    }

                    window.close();
                }, 100);
            })
        }
    },
    mounted() {
        this.orcidCallbackFun()
    }
}
</script>

<style lang='scss'></style>